import styled from '@emotion/styled';
import { Container,  Menu, MenuItem, Icon,AccordionTitle,AccordionContent, SidebarPushable, Sidebar } from 'semantic-ui-react'
import theme from '@styles/theme';
import { Link } from '@remix-run/react';
import { Logo } from '~/assets/svgs';

export const HeaderContainer = styled(Container)`
`
export const SidebarPushableContainer = styled(SidebarPushable)`
  transform: none !important;
`

export const SidebarTitle = styled(MenuItem)`
  height: 70px;
  margin: 0 !important;
  padding: 0 !important;
  background-color: grey;
`
export const SidebarNavItem = styled(MenuItem)`
  font-weight: bold !important;
  font-size: 20px;
  line-height: 28px;
  padding-left: 11px; !important;
`

export const SidebarNavItemMenu = styled(MenuItem)` 
  &&&& {
    overflow: visible;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }
`
export const NavSubMenu = styled(Menu)`
  &&&& {
    box-shadow: none;
    border: 0;
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    font-weight: light;
    background-color: ${theme.brand.colors.beige} ;
  }
`
export const NavSubMenuItem = styled(MenuItem)`
  &&&&& {
    padding-top: 5px;
    padding-left: 0;
    & > a {
      color: black;
    }
    :last-child {
      padding-bottom: 0.5em; 
    }
  }
`
export const LogoLink = styled(Link)`
  display: inline-block;
`
export const LogoIcon = styled(Logo)`
  float:left;
  height: 34px;
  padding-left: 40px;
`;
export const ShoppingCartIcon = styled(Icon)`
  padding-top: 7px;
  float: right;
`
export const HamburgerIcon = styled(Icon)`
&&& {
  cursor: pointer;
  vertical-align: top;
  display: inline-block;
  padding-top: 5px;
  margin-left: 16px;
  margin-right: 10px;
}  
`
export const CartLink = styled(Link)`
  color: black;
  &:hover{
    color: black;
  }
`
export const SubMenuIcon = styled(Icon)`
    float: right;
    color: #F3921B;

    &&&& {
        font-size: 12px;
    }
`

export const NavAccordionTitle = styled(AccordionTitle)`
    font-weight: bold;
    padding: 0.2em 0 0.2em 0 !important;
    font-size: 20px !important;
    line-height: 28px !important;
`
export const NavAccordionContent = styled(AccordionContent)`
`
export const ExternalNavLink = styled.a`

`
export const SidebarHeader = styled.div`
    padding: 18px 20px 18px 0px;
    height: 100%;
    vertical-align: middle;
    box-shadow: 0px 3px 6px #00000014;
    background-color: ${theme.brand.colors.white};
`
export const SidebarNavItemsContainer = styled.div`
  margin-top: 10px;
`

export const MobileTopMessageWrapper = styled.div`
`

export const SidebarContainer = styled(Sidebar)`
  &&&{
    background-color: ${theme.brand.colors.beige};
  }
`

export const MobileShoppingCartContainer = styled.div`
  float: right;
  padding-top: 5px; 
`